:root {
    --hd-header-font-family: var(--hd-default-font-family);
    --hd-header-background-color: rgb(252 251 251 / 50%);
    --hd-header-border-color: var(--hd-color-neutral-border-weak);
    --hd-header-height: var(--hd-space-8);
    --hd-header-shadow-1-block-color: rgba(175 182 225 / 80%);
    --hd-header-shadow-2-block-color: #F0E9E1;
    --hd-header-shadow-3-block-color: var(--hd-accent-100);
    --hd-header-shadow-1-top-position: -18.75rem;
    --hd-header-shadow-2-top-position: -21.25rem;
    --hd-header-shadow-3-top-position: -10.5rem;
}

[data-theme="dark"] {
    --hd-header-background-color: rgb(41 40 41 / 80%);
    --hd-header-shadow-1-block-color: rgba(175 182 225 / 70%);
    --hd-header-shadow-2-block-color: #F0E9E1;
    --hd-header-shadow-3-block-color: var(--hd-accent-900);
    --hd-header-shadow-1-top-position: -18.75rem;
    --hd-header-shadow-2-top-position: -31.25rem;
    --hd-header-shadow-3-top-position: -19.5rem;
}

.hd-product {
    display: flex;
    align-items: center;
    gap: var(--hd-space-1);
}

.hd-product__menu {
    width: 11.5rem;
    box-sizing: border-box;
}

.hd-product__menu [role="dialog"] {
    padding-inline: var(--hd-space-2);
    padding-block: var(--hd-space-3);
    border-color: transparent;
}

.hd-product__menu [role="dialog"]:focus-visible {
    outline: 0;
    box-shadow: var(--hd-focus-ring);
    border-radius: var(--hd-border-radius-sm);
}

.hd-product__items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style: none;
    margin-top: var(--hd-space-2);
}

.hd-product__title {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: var(--hd-color-neutral-text-weakest);
}

.hd-product__title + .hd-product__link {
    margin-top: var(--hd-space-1);
}

.hd-product__link {
    display: block;
    padding: var(--hd-space-1) var(--hd-space-1);
    border-radius: var(--hd-border-radius-sm);
    background-color: var(--hd-color-neutral-surface);
}

.hd-product__link:hover {
    background-color: var(--hd-color-neutral-surface-weak);
}

.hd-product__link:focus-visible {
    outline: 0;
    box-shadow: var(--hd-focus-ring);
}

.hd-header {
    display: flex;
    align-items: center;
    font-family: var(--hd-header-font-family);
    height: var(--hd-header-height);
    background-color: var(--hd-header-background-color);
    backdrop-filter: blur(1rem);
    border-block-end: var(--hd-border-size) solid var(--hd-header-border-color);
    border-radius: 0 0 var(--hd-border-radius-md) var(--hd-border-radius-md);
    z-index: var(--hd-z-index-1000);
}

.hd-header .hd-layout__fluid {
    padding-inline: 0;
}

@media screen and (width >= 48rem) {
    .hd-header {
        border-radius: var(--hd-border-radius-md);
        margin: 1.5rem auto 0;
        width: min(100% - 3rem, var(--layout-width));
    }
}

@media screen and (width >= 52.25rem) {
    .hd-header {
        position: sticky;
        top: 0;
    }
}

.hd-header__nav {
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    align-items: center;
    gap: var(--hd-space-6);
}

.hd-brand {
    display: flex;
}

.hd-brand:focus-visible {
    outline: 0;
    box-shadow: var(--hd-focus-ring);
    border-radius: var(--hd-border-radius-sm);
}

.hd-brand__logo {
    fill: var(--hd-color-neutral-icon);
}

.hd-header__quick-actions {
    display: none;
    gap: var(--hd-space-1);
}

@media screen and (width >= 52.25rem) {
    .hd-header__quick-actions {
        display: flex;
    }
}

.hd-header__mobile-menu-trigger {
    display: block;
}

@media screen and (width >= 52.25rem) {
    .hd-header__mobile-menu-trigger {
        display: none;
    }
}

/* Stylistic Gradient */
.hd-header__shadow {
    pointer-events: none;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: -1;
    top: -18.75rem;
    max-width: 100%;
}

.hd-header-shadow-block {
    position: relative;
    border-radius: var(--hd-space-7);
    filter: blur(13.5rem);

    /* prevents an issue in Safari where the blur wasn't visible */
    transform: translate3d(0, 0, 0);
}

.hd-header-shadow-block-1 {
    background-color: var(--hd-header-shadow-1-block-color);
    width: 32rem;
    height: 32rem;
    top: var(--hd-header-shadow-1-top-position);
    right: 6rem;
}

.hd-header-shadow-block-2 {
    width: 40rem;
    height: 40rem;
    background-color: var(--hd-header-shadow-2-block-color);
    filter: blur(12.5rem);
    top: var(--hd-header-shadow-2-top-position);
}

.hd-header-shadow-block-3 {
    background-color: var(--hd-header-shadow-3-block-color);
    width: 32rem;
    height: 32rem;
    top: var(--hd-header-shadow-3-top-position);
    left: -10.5rem;
}
